@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Satoshi", "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: radial-gradient(40% 40% at 30% 40%, #ebadeb18, transparent),
    radial-gradient(40% 40% at 50% 70%, #bea3f520, transparent),
    radial-gradient(40% 40% at 70% 40%, #a8f0f020, transparent);
  background-color: hsl(230, 20%, 8%); */

  /* background: radial-gradient(40% 40% at 30% 40%, #ebadeb18, transparent),
    radial-gradient(40% 40% at 50% 70%, #bea3f518, transparent),
    radial-gradient(40% 40% at 70% 40%, #a8f0f018, transparent); */

  background: radial-gradient(40% 40% at 30% 40%, #ebadeb18, transparent),
    radial-gradient(40% 40% at 50% 70%, #bea3f518, transparent),
    radial-gradient(40% 40% at 70% 40%, #629ed923, transparent);
  background-color: hsl(230, 20%, 10%);

  /* background: radial-gradient(40% 40% at 30% 40%, #ebadeb18, transparent),
    radial-gradient(40% 40% at 50% 70%, #bea3f518, transparent),
    radial-gradient(40% 40% at 70% 40%, #afa8f018, transparent);
  background-color: hsl(230, 20%, 10%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .link {
    @apply bg-transparent py-2 px-3 rounded-md hover:bg-white/[.02];
  }
  .link__active {
    @apply bg-white/[.05];
  }
}
